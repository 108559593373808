import { render, staticRenderFns } from "./CurrentYear.vue?vue&type=template&id=755a2425&"
import script from "./CurrentYear.vue?vue&type=script&lang=js&"
export * from "./CurrentYear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('755a2425')) {
      api.createRecord('755a2425', component.options)
    } else {
      api.reload('755a2425', component.options)
    }
    module.hot.accept("./CurrentYear.vue?vue&type=template&id=755a2425&", function () {
      api.rerender('755a2425', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8jyc9DNhqpD33/JS/comp/CurrentYear.vue"
export default component.exports