import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	fireBooking
}
from 'voUtils/TagEx.js';

import {
	getAbTest
}
from 'voUtils/AbTest.js';

import {
	doPost
}
from 'voUtils/RestEx.js';
import {
	getVoucher
}
from 'voUtils/VoucherStore.js';
import {
	getUserInfo
}
from 'voUtils/TagEx.js';

import {
	getRootUrl
}
from 'voUtils/BrowserEx.js';

import {
	getAgioAmount
}
from 'voUtils/QuoteTools.js';

import {
	requireLibs
}
from 'voUtils/loader.js';


export default {
	voVueComponent: 'voffice-booking',
	voMsgCodes: [
		'schedule.prepayment',
		'schedule.rest',
		'schedule.total',
		'schedule.onsite',
	],
	data: function () {
		var pd = VOFFICE.testmode ? {
			title: 'Frau',
			forename: 'Erika',
			surname: 'Mustermann',
			email: VOFFICE.testmode.email || 'mustermann@v-office.com',
			mobile: '01749342333',
			message: 'Testbuchung',

			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
				street: 'Am Berg',
				housenumber: '33',
				postalcode: '23730',
				city: 'Neustadt'
			}
		} : {
			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
			}
		};

		return {
			pd: pd,
			coupon: {},
			status: undefined,
			showPriceDetails: false,
			bookingResult: undefined,
			error: undefined,
		};
	},
	props: {
		action: String,
		quote: Object,
		bookingData: Object,
		unitId: Number,
		cancel: Function,
		// Ergo Special
		ergoIds: Array,
		ergoAddOnIds: Array,
		ergoSelected: Boolean,
	},
	created: function () {
		this.QuoteTools = QuoteTools;
	},

	methods: {
		// Ergo Specials
		isErgoSl(sl) {
				return this.ergoIds.includes(sl.service.id);
			},
			isDisabledInsurance(quote, sl) {
				return (this.ergoSelected && this.isErgoSl(sl) && !quote.mandatory.includes(sl)) || (this.isErgoAddOn(sl) && !this.ergoSelected);
			},
			isErgoAddOn(sl) {
				return this.ergoAddOnIds.includes(sl.service.id);
			},
			showInsuraceModal(sl) {
				/*if (sl.service.id == 24609) {
					$('#modal-ERGO-4577283').modal();
				} else if (sl.service.id == 24608) {
					$('#modal-ERGO-4577274').modal();
				} else if (sl.service.id == 29192) {
					$('#modal-ERGO-4577296').modal();
				}*/
				if (sl.service.id == 49318) {
					$('#modal-ERGO-14408586').modal();
				} else if (sl.service.id == 49319) {
					$('#modal-ERGO-14408046').modal();
				} else if (sl.service.id == 49320) {
					$('#modal-ERGO-14408945').modal();
				}
			},

			goBook: function () {

				var req = {
					reservation: {
						customer: this.pd
					},
					action: this.action,
					rootUrl: getRootUrl(),
					bookings: []

				};

				if (VOFFICE.currency) {
					req.reservation.customer.currency = VOFFICE.currency;
				}

				var voucher = getVoucher();
				if (voucher) {
					req.voucherCode = voucher.code;
				}
				if (this.coupon.ok) {
					req.couponCode = this.coupon.code;
				}


				if (getAbTest()) {
					req.abtest = getAbTest();
				}

				if (VOFFICE.newsletter && VOFFICE.newsletter.def) {
					req.newsletter = VOFFICE.newsletter.def;
				}

				if (req.action === 'booking') {
					req.paymentMethods = VOFFICE.paymentMethods;
				}

				var ui = getUserInfo();
				if (ui) {
					req.ui = ui;
				}

				if (VOFFICE.bookingOptions) {
					Object.assign(req, VOFFICE.bookingOptions);
				}


				var b = Object.assign({
					unit: this.unitId
				}, this.bookingData);


				if (this.quote.oMandatory) {
					b.servicelines = [...this.quote.oMandatory];
					for (let sl of this.quote.mandatory) {
						if (sl.booked) {
							b.servicelines.push(sl);
						}
					}
				} else {
					b.servicelines = this.quote.mandatory;
				}

				var onsite = find(this.quote.onsite, {
					onsiteOptional: true
				});
				if (this.quote.onsite.length) {
					b.servicelines = this.quote.mandatory.concat(this.quote.onsite);
				}


				req.bookings = [b];

				this.error = undefined;
				this.status = 'booking';
				doPost('book', req)
					.then(res => {
						this.status = 'done';
						this.coupon = {};
						res.reservation.payNow = this.getPayNow(res.reservation.paymentSchedule);

						this.bookingResult = res.reservation;
						this.paymentMethods = res.paymentMethods;

						fireBooking(req, res);

					}).catch(e => {
						V.logWarn("book error", e);
						this.status = 'error';
						this.error = e;
					});

			},
			getPayNow: function (paymentSchedule) {
				var res = {};
				//_.each(paymentSchedule, function (val, key) {
				if (paymentSchedule) {
					for (let [key, val] of Object.entries(paymentSchedule)) {
						let ps = Object.assign({}, val);
						if (key === 'rest') {
							if (paymentSchedule.prepayment) {
								ps.amount += paymentSchedule.prepayment.amount;
							}
							key = 'total';
						}
						if (key !== 'onsite') {
							res[key] = ps;
						}

					}
				}
				return res;
			},
			hasTypeBooked: function (quote, type) {
				if (quote && quote.mandatory) {
					for (let sl of quote.mandatory) {
						if (sl.amount && sl.service.type === type) {
							return true;
						}
					}
				}
				return false;

			},
			preparePaypal: function (paypal, origAmount) {
				var res = Object.assign({}, paypal.button);
				var amount = getAgioAmount(origAmount, paypal);
				var base = getRootUrl();

				res.amount = amount / 100;
				res.cancel_return = base + res.cancel_return;
				res.return = base + res.return;
				res.currency_code = VOFFICE.currency || 'EUR';
				res.custom = origAmount + ':' + amount;

				return res;
			},
			checkoutStripe: function (pm, r, amount) {

				var onStripeLoaded = requireLibs([
					"stripe.js",
				]);

				var req = {
					id: r.id,
					nr: r.nr,
					amount: amount,
					paymentMethodId: pm.id,
					rootUrl: getRootUrl()
				};

				doPost('checkoutPayment', req)
					.then(res => {
						onStripeLoaded(() => {
							var stripe = Stripe(pm.paymentTest ? 'pk_test_62sy8HrukNuTd7ko6lZcyJcv00CtNmm5oo' : 'pk_live_lHTr8iFxjPqtC0aYmkQYZuzZ00qtUAATYP', {
								stripeAccount: res.accountId
							});

							stripe.redirectToCheckout({
								// Make the id field from the Checkout Session creation API response
								// available to this file, so you can provide it as parameter here
								// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
								sessionId: res.sessionId
							}).then(function (result) {
								V.log("result", result);
								// If `redirectToCheckout` fails due to a browser or network
								// error, display the localized error message to your customer
								// using `result.error.message`.
								if (result.error) {
									this.error = {
										reasonText: result.error.message
									};
								}
							});
						});

					}).catch(e => {
						V.logWarn("checkout error", e);
						this.error = e;
					});



			},
			checkCoupon: function () {
				var coupon = this.coupon;
				coupon.checking = true;
				let total = QuoteTools.getTotal(this.quote, 'all');
				doPost('checkCoupon', coupon)
					.then(res => {
						res.cash = Math.min(total, res.available);
						Object.assign(coupon, res);
					}).catch(e => {
						V.logWarn("checkcoupon error", e);
						this.error = e;
					}).finally(e => {
						coupon.checking = false;
						this.$forceUpdate();
					});


			}


	}
};