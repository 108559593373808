import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

export default {
	voVueComponent: 'panorama',
	data: function () {
		return {};
	},
	props: {
		panolist: Array
	},
	mounted: function () {
		var panos = {};
		var maxSize;
		try {
			var canvas = document.createElement('canvas');
			var gl = canvas.getContext('experimental-webgl');
			maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
		} catch (e) {
			console.log("could not detect maxSize");
		}
		var size = (maxSize && maxSize <= 4096) ? 'gl' : 'pano';

		for (let pano of this.panolist) {
			pano.type = "equirectangular";
			pano.panorama = 'https://dyn.v-office.com/image/' + size + '/' + pano.id + '.jpg';
			pano.title = pano.title;
			if (!pano.hfov) {
				pano.hfov = 120;
			}
			if (pano.hotSpots) {
				for (let hs of pano.hotSpots) {
					var linkedPanorama = hs.linkedPanorama;
					if (linkedPanorama) {
						hs.sceneId = 'p' + linkedPanorama;
					}
					if (!hs.text) {
						hs.text = 'Hotspot';
					}
					if (hs.linkedPanorama) {
						delete hs.linkedPanorama;
					}
				}
			}
			panos['p' + pano.id] = pano;

		}
		pannellum.viewer('panorama', {
			"default": {
				"firstScene": 'p' + this.panolist[0].id,
				"autoLoad": true,
				"sceneFadeDuration": 1000,
				"autoRotate": -2
			},
			"scenes": panos
		});
	}
};