import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

import {
	fireFormSubmit
}
from 'voUtils/TagEx.js';

import {
	doPost
}
from 'voUtils/RestEx.js';
import {
	findPreviousSiblingByName
}
from 'voUtils/BrowserEx.js';

export default {
	voVueComponent: 'voffice-form',
	data: function () {
		var fd = VOFFICE.testmode ? {
			forename: 'John',
			surname: 'Doe',
			email: 'test@test.de',
			mobile: '342344 234234',
			message: 'Hallo World!'
		} : {
			country: 'Deutschland'
		};
		return {
			formData: fd,
			status: 'ready',
			error: undefined,
			countries: [
				'Österreich', 'Schweiz', 'Luxemburg', 'Afghanistan', 'Ägypten', 'Åland', 'Albanien', 'Algerien', 'Amerikanisch-Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarktis', 'Antigua/Barbuda', 'Äquatorialguinea', 'Argentinien', 'Armenien', 'Aruba', 'Aserbaidschan', 'Äthiopien', 'Australien', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belgien', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivien', 'Bosnien/Herzegowina', 'Botsuana', 'Bouvetinsel', 'Brasilien', 'Brunei Darussalam', 'Bulgarien', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Chile', 'China', 'Cookinseln', 'Costa Rica', 'Cuba', 'Deutschland', 'Dominica', 'Dominikanische Republik', 'Dschibuti', 'Dänemark', 'Ecuador', 'El Salvador', 'Elfenbeinküste', 'Eritrea', 'Estland', 'Falklandinseln', 'Fidschi', 'Finnland', 'Frankreich', 'Französisch-Guayana', 'Französisch-Polynesien', 'Französische', 'Südpolarterritorien', 'Färöer', 'Gabun', 'Gambia', 'Georgien', 'Ghana', 'Gibraltar', 'Grenada', 'Griechenland', 'Großbritannien', 'Grönland', 'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 'Guinea-Bissau', 'Guinea', 'Guyana', 'Haiti', 'Heard und McDonaldinseln', 'Honduras', 'Hong Kong', 'Indien', 'Indonesien', 'Irak', 'Iran', 'Irland', 'Island', 'Isle Of Man', 'Israel', 'Italien', 'Jamaika', 'Japan', 'Jemen', 'Jersey', 'Jordanien', 'Jungferninseln, Amerikanische', 'Jungferninseln, Britische', 'Kaimaninseln', 'Kambodscha', 'Kamerun', 'Kanada', 'Kasachstan', 'Kenia', 'Kirgisistan', 'Kiribati', 'Kokosinseln', 'Kolumbien', 'Komoren', 'Kongo', 'Kroatien', 'Kuwait', 'Laos', 'Lesotho', 'Lettland', 'Libanon', 'Liberia', 'Libyen', 'Liechtenstein', 'Litauen', 'Macao', 'Madagaskar', 'Malawi', 'Malaysia', 'Maldiven', 'Mali', 'Malta',
				'Marshallinseln', 'Martinique', 'Mauretanien', 'Mauritius', 'Mayotte', 'Mazedonien', 'Mexiko', 'Mikronesien', 'Moldawien', 'Monaco', 'Mongolei',
				'Montenegro', 'Montserrat', 'Morokko', 'Mosambik', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Neukaledonien', 'Neuseeland', 'Nicaragua', 'Niederlande', 'Niederländische Antillen', 'Nigeria', 'Niger', 'Niue', 'Nordkorea', 'Norfolkinsel', 'Norwegen', 'Nördliche Marianen', 'Oman', 'Pakistan', 'Palau', 'Palestina', 'Panama', 'Papua-Neuguinea', 'Paraguay', 'Peru', 'Philippinen', 'Pitcairninseln', 'Polen', 'Portugal', 'Puerto Rico', 'Qatar', 'Ruanda', 'Rumänien', 'Russland', 'Réunion', 'Salomonen', 'Sambia', 'Samoa', 'San Marino', 'Saudi-Arabien', 'Schweden', 'Senegal', 'Serbien', 'Seychellen', 'Sierra Leone', 'Simbabwe', 'Singapur', 'Slowakische Republik', 'Slowenien', 'Somalia', 'Spanien', 'Sri Lanka', 'St. Barthélemy', 'St. Helena', 'St. Kitts/Nevis', 'St. Lucia', 'St. Martin', 'St. Pierre/Miquelon', 'St. Vincent/Die Grenadinen', 'Sudan', 'Surinam',
				'Svalbard/Jan Mayen', 'Swasiland', 'Syrien', 'São Tomé/Príncipe', 'Südafrika', 'Südgeorgien/Südlichen Sandwichinseln', 'Südkorea', 'Tadschikistan', 'Taiwan', 'Tansania', 'Thailand', 'Timor-Leste', 'Togo', 'Tokelau', 'Tonga', 'Trinidad und Tobago', 'Tschad', 'Tschechoslowakei', 'Tunisien', 'Turkmenistan', 'Turks- und Caicosinseln', 'Tuvalu', 'Türkei', 'Uganda', 'Ukraine', 'Ungarn', 'United States Minor Islands', 'Uruguay', 'USA', 'Usbekistan', 'Vanuatu', 'Vatikanstadt', 'Venezuela', 'Vereinigte Arabische Emirate', 'Vietnam', 'Wallis/Futuna',
				'Weihnachtsinsel', 'Weißrussland', 'Westsahara', 'Zentralafrikanische Republik', 'CZypern'
			]
		};
	},
	props: {
		subject: String,
		ngaction: String
	},
	mounted: function () {

		var labels = {};
		var order = [];

		this.$el.querySelectorAll('[data-formdata-field]').forEach((el) => {
			var field = el.getAttribute('data-formdata-field');
			let label = el.getAttribute('voffice-form-field-label');

			if (!label) {
				let labelEl = findPreviousSiblingByName(el, 'label');
				if (!labelEl) {
					let id = el.getAttribute('id');
					if (id) {
						labelEl = this.$el.querySelector('[for="' + id + '"]');
					}
				}
				if (labelEl) {
					label = labelEl.textContent;
				}

			}

			if (label) {
				labels[field] = label;
			} else {
				V.warn(`could not find label for field ${field}, please use label, 'for' or 'voffice-form-field-label' attribute`);
			}

			order.push(field);


		});

		this.labels = labels;
		this.order = order;

	},
	methods: {
		submit: function () {
			this.status = 'submitting';

			var data = {
				subject: this.subject,
				form: DateEx.formatDates(this.formData, undefined, VOFFICE.mlang),
				search: DateEx.encodeDates(this.$parent.searchData),
				unit: this.$parent.getUnitId && this.$parent.getUnitId(),
				labels: this.labels,
				order: this.order
			};


			fireFormSubmit(data);
			this.error = undefined;

			doPost(this.ngaction || 'saveMessage', data)
				.then((res) => {

					this.status = 'done';


				}).catch(e => {
					this.status = 'failed';
					this.error = e;
				});





		}
	}
};